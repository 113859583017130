import axios from 'axios';
import { setAlert } from './alert';
import { USER_LOADED, LOGIN_SUCCESS, LOGOUT, AUTH_ERROR, FORGET_PASSWORD, RECOVERY_PASSWORD, CHANGE_PROFILE, USER_NOORGANIZATION, REGISTER, LOAD_REGISTER } from "./types";
import setToken from "../../src/utility/setToken";
import React from 'react';
import getFirebaseToken from '../utility/firebase';
import { isMobile } from 'react-device-detect';

export const loadUser = () => async dispatch => {
  try {
    let firebaseToken = await getFirebaseToken();
    if (firebaseToken === undefined || firebaseToken === null) {
      firebaseToken = localStorage.fcm === undefined || localStorage.fcm === null ? "" : localStorage.fcm;
    }
    console.log(firebaseToken);

    try {
    const res = await axios.get(`/auth/current-user?firebaseToken=${firebaseToken}`)
    if (res.data !== undefined && res.data !== null) {
      if (res.data.token !== "")
        setToken(res.data.token, res.data.organizationID);
    }
    dispatch({
      type: USER_LOADED,
      payload: await res.data,
    });
  } catch (error) {
    // Error for auth/current-user is ignored
    if (isMobile && localStorage.alias !== undefined && localStorage.alias !== null) {
          const relogin = await axios.post(`/Mobile/relogin?firebaseToken=${firebaseToken}&orgName=${localStorage.alias}`);
        if (relogin.data !== undefined && relogin.data !== null) {
          if (relogin.data.token !== "")
            relogin.data.alias = localStorage.alias;
            dispatch({
              type: LOGIN_SUCCESS,
              payload: await relogin.data
            });
            var data = relogin.data.data
            setToken(relogin.data.token, data.organizationMembers[0].organizationID);
            delete relogin.data
            relogin.data = data;
            return relogin;
        }
      }
      else{
        throw new Error('Error Get User Info');
      }
  }
    

  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch(setAlert(errMessage, 'danger'));
    dispatch({
      type: AUTH_ERROR
    })
  }
}

export const login = ({ email, password }) => async dispatch => {
  try {
    const body = { email, password };
    const res = await axios.post('/auth/login', body);
    if (await res.data.data.organizationMembers.length > 0) {
      setToken(res.data.token, res.data.data.organizationMembers[0].organizationID);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: await res.data
      });
      dispatch(loadUser());
    } else {
      setToken(await res.data.token, 0);
      dispatch({
        type: USER_NOORGANIZATION,
        payload: res.data
      });
    }
    return res.data;
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch({
      type: AUTH_ERROR
    });
    if (errMessage === "false") { return errMessage; }
    dispatch(setAlert(errMessage, 'danger'));
    return Promise.reject();
  }
}

export const loginMobile = ({ alias, noHp }) => async dispatch => {
  try {
    const body = { alias, noHp };
    const res = await axios.post('/mobile/login', body);
    if (await res.data.data.organizationMembers.length > 0) {
      setToken(res.data.token, res.data.data.organizationMembers[0].organizationID);
      res.data.alias = alias;
      dispatch({
        type: LOGIN_SUCCESS,
        payload: await res.data
      });
    }
    else {
      setToken(await res.data.token, 0);
    }
    dispatch({
      type: USER_NOORGANIZATION,
      payload: await res.data
    });
    dispatch(loadUser());
    return Promise.resolve();
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch({
      type: AUTH_ERROR
    });
    if (errMessage === "false") { return errMessage; }
    dispatch(setAlert(errMessage, 'danger'));
    return Promise.reject();
  }
}

export const logout = ({ email }) => async dispatch => {
  try { 
    const body = { email };
    await axios.post('/auth/logout', body).catch(function (error) {
      if (error.response) {
        console.log("Logout failed");
      }
    });
    dispatch({ type: LOGOUT });
    return "OK";
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch(setAlert(errMessage, "danger"));
    return Promise.reject(errMessage);
  }
}

//callback
const AuthCallback = () => {
  React.useEffect(() => {
    // Extract the code from URL query
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      axios
        .post('/authentication/exchange-token', { code })
        .then((response) => {
          // Store the access token in local storage or context for future requests
          localStorage.setItem('accessToken', response.data.accessToken);
          // Redirect to your app's authenticated state
          window.location.href = '/home';
        })
        .catch((error) => {
          console.error('Error exchanging token:', error);
          // Handle error case
        });
    }
  }, []);

  return <div>Loading...</div>;
};

//login by google
export const loginByGoogle = (googleResponse) => async (dispatch) => {
  try {
    // const tokenId = googleResponse.tokenId;
    const res = await axios.get(`/Auth/signin-google`); // Menggunakan metode GET
    if (res.data.data.organizationMembers.length > 0) {
      setToken(res.data.token, res.data.data.organizationMembers[0].organizationID);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      dispatch(loadUser());
    } else {
      setToken(res.data.token, 0);
      dispatch({
        type: USER_NOORGANIZATION,
        payload: res.data
      });
    }
    return res.data;
  } catch (err) {
    var errMessage = err.message || (err.response && err.response.data && err.response.data.message) || 'An error occurred';
    dispatch({
      type: AUTH_ERROR
    });
    if (errMessage === "false") {
      return errMessage;
    }
    dispatch(setAlert(errMessage, 'danger'));
  }
};

export const forgetPassword = ({ email }) => async dispatch => {
  try {
    const body = { email };
    const res = await axios.post('/auth/forget-password', body);
    dispatch({
      type: FORGET_PASSWORD,
      payload: res.data
    });
    return Promise.resolve();
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch(setAlert(errMessage, "danger"));
    return Promise.reject(errMessage);
  }
}

export const resendActivation = ({ email }) => async dispatch => {
  try {
    //const body = { Email: email };
    await axios.post(`/auth/resendActivation?email=${email}`);
    return Promise.resolve();
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch(setAlert(errMessage, "danger"));
    return Promise.reject(errMessage);
  }
}


export const resendActivationCheck = ({ emailVal }) => async dispatch => {
  try {
    //const body = { Email: email };
    await axios.post(`/auth/resendactivationcheck?email=${emailVal}`);
    return Promise.resolve();
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;

    let strArr = errMessage.split(" ");
    if (strArr.length > 0) {
      let ff = strArr[strArr.length - 1];
      if (ff !== "again")
        dispatch(setAlert(ff, "danger"));
    }
    return Promise.reject(errMessage);
  }
}
export const register = ({ fullName, email, password }) => async dispatch => {
  try {
    const body = { fullName, email, password };
    const res = await axios.post('/auth/register', body);
    dispatch({
      type: REGISTER,
      payload: res.data
    });
    return Promise.resolve();
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    if (errMessage !== "User already Found")
      dispatch(setAlert(errMessage, "danger"));
    return Promise.reject(errMessage);
  }
}

export const registeractive = ({ uId }) => async dispatch => {
  try {
    await axios.post(`/auth/registeractivate/${uId}`);
    return Promise.resolve();
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch(setAlert(errMessage, "danger"));
    return Promise.reject(errMessage);
  }
}

export const registerinvitation = ({ id }) => async dispatch => {
  try {
    const res = await axios.get(`/auth/registerinvitation/${id}`);
    dispatch({
      type: LOAD_REGISTER,
      payload: res.data,
    });
    return Promise.resolve(res.data.data)
  } catch (err) {
    let errMessage = "";
    if (err.message) errMessage = err.message;
    if (err.response && err.response.data && err.response.data.message) errMessage = err.response.data.message;
    dispatch(setAlert(errMessage, "danger"));
  }
}

export const organizationProgress = ({ uId }) => async dispatch => {
  return true;
}

export const recoveryPassword = ({ body }) => async dispatch => {
  try {
    const res = await axios.post('/auth/recovery-password', body);
    dispatch({
      type: RECOVERY_PASSWORD,
      payload: res.data
    });
    return Promise.resolve();
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch(setAlert(errMessage, "danger"));
    return Promise.reject(errMessage);
  }
}

export const changeProfile = ({ body }) => async dispatch => {
  try {
    const res = await axios.post('/auth/change-profile', body);
    dispatch({
      type: CHANGE_PROFILE,
      payload: res.data
    });
    dispatch(loadUser());
  } catch (err) {
    var errMessage = "";
    if (err.message != null)
      errMessage = err.message;
    if (err.response != null && err.response.data != null && err.response.data.message != null)
      errMessage = err.response.data.message;
    dispatch(setAlert(errMessage, "danger"));
  }
}



