import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { firebaseConfig, firebasePublicKey } from './config';
import { browserName } from 'react-device-detect';

let messaging = null;

// Get Firebase token
const getFirebaseToken = async () => {
  // console.log("Safari");
  if (browserName !== "WebKit") {

    // Register Service Worker
    navigator.serviceWorker.register("/firebase-messaging-sw.js").then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch(err => {
      console.error('Service Worker registration failed', err);
    });

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
    let permission = "";
    try {
      if (browserName === "Safari") {
        if (!'Notification' in window) {
          // If the browser version is unsupported, remain silent.
          return;
        }

        if (Notification.permission === 'default') {
          Notification.requestPermission(function () {
            // ...callback this function once a permission level has been set.
            permission = "granted";
          });
        }
        permission = Notification.permission;
      } else {
        permission = await Notification.requestPermission();
      }

      if (permission === "granted") {
        const messagingResolve = await messaging;
        if (messagingResolve) {
          const token = await getToken(messagingResolve, { vapidKey: firebasePublicKey });
          if (!token)
            throw new Error('Failed to get device token');
          // return null;
          console.log('Generated Token:', token);
          return token;
        }
        return null;
      } else if (permission === "denied") {
        console.log("You denied for the notification");
        return false;
      }
    } catch (error) {
      console.error("An error occurred while retrieving the device token: ", error);
      return null;
    }
  }
  return null;
}

export default getFirebaseToken;
export { messaging }