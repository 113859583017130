import React from 'react';
import { loadUser } from '../../actions/auth';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
    
    isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }

      componentDidMount() {

        if (!this.isValidUrl(this.props.url)) {
          return;
        }
        fetch(this.props.url)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            else if (response.status === 401) {
              loadUser();
            }
            else if (response.status === 400) {
              window.location.reload();
            }
            return response.json();
          })
          .then((data) => {
            this.setState({ data });
          })
          .catch((error) => {
            this.setState({ error });
            // You can log the error or display a user-friendly message here
            console.error(error);
          });
      }
      
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
 
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1 className='d-none'>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }

export default ErrorBoundary; 